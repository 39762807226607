import React, { useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'
import Cta from '../Cta'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { format } from 'react-string-format'

const footer = ({ isContactPage }) => {
    useEffect(() => {
        // Mount

        // Unmount
        return () => {
        }
    }, [])

    const data = useStaticQuery(graphql`
  query FooterQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
        footer_text_title {
          value
        }
        footer_text {
          value
        }
        footer_useful_links_title {
          value
        }
        footer_contact_title {
          value
        }
      }
    }
    kontentItemMenu(system: {codename: {eq: "footer_menu"}}) {
      elements {
        menu_items {
          value {
            ... on kontent_item_menu_item {
              system {
                id
              }
              id
              elements {
                title {
                  value
                }
                content {
                  value {
                    ... on kontent_item_contact_page {
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                    ... on kontent_item_simple_page {
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }   
              }
            }
          }
        }
      }
    }
    kontentItemContactPage(system: {codename: {eq: "kontakt"}}) {
      id
      system {
        id
      }
      elements {
        email {
          value
        }
        phone {
          value
        }
        phone_mobile {
          value
        }
        address {
          value
        }
      }
    }
  }
`
    )

    const meta = data.kontentItemSiteMetadata.elements
    const menu = data.kontentItemMenu.elements
    const contact = data.kontentItemContactPage.elements
    const today = new Date();
    const isContact = isContactPage
    const footerTextTitle = meta.footer_text_title.value
    const footerContactTitle = meta.footer_contact_title.value
    const footerContactEmail = contact.email.value
    const footerContactPhone = contact.phone.value
    //const footerContactPhoneMobile = contact.phone_mobile.value

    return (
        <div>
            <footer className="footer-content">
                {isContact == null ? (
                    <Cta />
                ) : ""}
                <div className="main-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="footer-heading">
                                    <h2>{footerTextTitle}</h2>
                                </div>                                
                                <RichTextElement
                                    value={contact.address.value} />
                            </div>
                            <div className="col-lg-5">
                                <div className="footer-heading">
                                    <h2>{footerContactTitle}</h2>
                                </div>
                                <ul className="footer-content__contact-info">
                                    <li>Email: <a href={format('mailto:{0}', footerContactEmail)} data-tracking-label="Footer_Kontakt_Email">{footerContactEmail}</a></li>
                                    <li>Telefon: <a href={format('tel:{0}', footerContactPhone)} data-tracking-label="Footer_Kontakt_Phone">{footerContactPhone}</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <div className="footer-heading">
                                    <h2>{meta.footer_useful_links_title.value}</h2>
                                </div>
                                <ul className="useful-links">
                                    {menu.menu_items.value.map(item => (
                                        <li className="useful-links__list-item" key={item.id}>
                                            <Link
                                                to={`${item.elements.content.value[0].elements.slug.value.startsWith('/') ? '' : '/'}${item.elements.content.value[0].elements.slug.value}`}
                                                className="useful-links__list-item-link"
                                                activeClassName="useful-links__list-item-link--active"
                                                data-tracking-label={format('Footer_UsefulLinks_{0}', item.elements.title.value)}
                                            >
                                                {item.elements.title.value}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-lg-12">
                                <div className="sub-footer">
                                    <p>Copyright © {today.getFullYear()} <a href="/kontakt" data-tracking-label="Footer_Copyright_OMne">{meta.title.value}</a>. Všechna práva vyhrazena.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default footer
