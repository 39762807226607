import React from 'react'
import { Link } from 'gatsby'

class Cta extends React.Component {

    render() {
        return (
            <div>
                <div className="cta-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h2>Zaujaly vás naše kanceláře?</h2>
                            </div>
                            <div className="col-lg-4 cta-footer__button">
                                <Link to="/kontakt" className="button button--inverted" data-tracking-label="CTABanner_ContactUs">Pošlete nám dotaz</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cta
