import React from 'react'

class PageHeading extends React.Component {

    render() {
        const pageTitle = this.props.data

        return (
            <div>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-heading__title">{pageTitle}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageHeading
